<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Save Project Search</h5>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label class="col-form-label font-weight-bold" for="name">Name <span class="red-dot ml-3"></span></label>
          <input
            class="w-100 rounded p-1 px-2"
            :class="{ 'input-validation-error': errors.has('name') }"
            id="name"
            name="name"
            placeholder="Enter search name"
            autocomplete="off"
            type="text"
            v-validate="'required'"
            v-model="name"
            data-vv-as="Name"
          />
          <span class="field-validation-error" v-if="errors.has('name')">The Name field is required.</span>
        </div>
        <div v-if="$permissions().isClientAdmin || $permissions().isSysAdmin">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="isPublic" name="isPublic" id="isPublicFalse" value="false">
            <label class="form-check-label" for="isPublicFalse">Private</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="isPublic" name="isPublic" value="true">
            <label class="form-check-label" for="isPublicTrue">Public</label>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary">Save</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "project-saved-search",
  props: ["searchCriteria"],
  data: function() {
    return {
      name: null,
      isPublic: false
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser"])
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      let model = {
        name: this.name,
        isPublic: this.isPublic,
        userId: this.getCurrentUser.id,
        ...this.searchCriteria
      };

      this.saveProjectSearch(model).then(() => {
        this.$root.$bvToast.toast("Successfully saved!", {
          noCloseButton: true,
          autoHideDelay: 3000,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          appendToast: true,
          isStatus: true
        });
        this.$bvModal.hide("bv-modal-project-saved-search");
      });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-project-saved-search");
    },
    ...mapActions(["saveProjectSearch"])
  }
};
</script>
