<script>
export default {
  name: 'google-map-marker',
  props: {
    google: { type: Object, required: true },
    map: { type: Object, required: true },
    info: { type: Object, required: true },
    marker: { type: Object, required: true }
  },
  render () {
    return null;
  },
  mounted () {
    let marker = new this.google.maps.Marker({
      title: this.marker.title,
      position: this.marker.position,
      map: this.map
    });

    marker.addListener('click', () => {
      this.info.setContent(this.marker.content);
      this.info.open(this.map, marker)
    });
  }
};
</script>
