<template>
  <fragment>
    <form @submit.prevent>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Generate Bulletin Report</h5>
      </div>

      <div class="modal-body">
        <div class="form-group row">
          <label for="staticEmail" class="col-sm-4 col-form-label">Choose a Template <span class="red-dot"></span></label>
          <div class="col-sm-8">
            <select id="template" name="template" class="custom-select" :class="{ 'is-invalid': errors.has('template') }"
                v-model="reportRequest.template" v-validate="'required'">
              <option value="bulletin_report">Bulletin Report</option>
              <option value="project_details">Project Details</option>
              <option value="search_report">Projet Search Report</option>
              <option value="lows_awards">Lows and Awards</option>
            </select>
            <div class="invalid-feedback">Please select a template.</div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword" class="col-sm-4 col-form-label">Report Name</label>
          <div class="col-sm-8">
            <input id="name" name="name" type="text" class="form-control" :class="{ 'is-invalid': errors.has('name') }"
              autocomplete="off" maxlength="140" v-model="reportRequest.name" v-validate="'max:140'" />
            <div class="invalid-feedback">Max allowed characters is 140.</div>
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword" class="col-sm-4 col-form-label">Report Format <span class="red-dot"></span></label>
          <div class="col-sm-8">
            <select id="format" name="format" class="custom-select" :class="{ 'is-invalid': errors.has('format') }"
                v-model="reportRequest.format" v-validate="'required'">
              <option value="pdf">PDF</option>
              <option value="html">HTML</option>
              <option value="word">Word</option>
              <option value="excel">Excel</option>
            </select>
            <div class="invalid-feedback">Please select a format.</div>
          </div>
        </div>
        <div class="form-group row">
          <label for="manipulateBy" class="col-sm-4 col-form-label">Project Order</label>
          <div class="col-sm-4">
            <select id="manipulateBy" name="manipulateBy" class="custom-select" v-model="reportRequest.manipulateBy" @change="onManipulateByChanged">
              <option value="sortBy">Sort By</option>
              <option value="groupBy">Group By</option>
            </select>
          </div>
          <div class="col-sm-4">
            <select id="manipulateDirection" name="manipulateDirection" class="custom-select" v-model="reportRequest.manipulateDirection">
              <option v-for="option in manipulateDirectionValues" :key="option.key" :value="option.key">{{ option.value }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-success" @click="onSubmit">Generate Report</button>
        <button type="button" class="btn btn-success" @click="onReset">Reset</button>
        <button type="button" class="btn btn-secondary" @click="onCloseModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
import Toast from "@/utils/toast";
import { ProjectService } from '@/services/'

export default {
  name: "project-bullet-report",
  data: function() {
    return {
      reportRequest: {
        template: null,
        name: null,
        format: null,
        manipulateBy: null,
        manipulateDirection: null
      },
      manipulateByGroupByOptions: [
        { key: 'stage', value: 'Stage' }, { key: 'zone', value: 'Zone' }, { key: 'funding', value: 'Funding Type' }
      ],
      manipulateBySortByOptions: [
        { key: 'projectTitle', value: 'Project Title' }, { key: 'closingDate', value: 'Closing Date' }, { key: 'tenderStage', value: 'Tender Stage' }
      ]
    };
  },
  computed: {
    manipulateDirectionValues () {
      if (this.reportRequest.manipulateBy === 'groupBy')
        return this.manipulateByGroupByOptions;
      if (this.reportRequest.manipulateBy === 'sortBy')
        return this.manipulateBySortByOptions;

      return [];
    }
  },
  methods: {
    onManipulateByChanged () {
      if (this.reportRequest.manipulateBy === 'groupBy') {
        this.reportRequest.manipulateDirection = this.manipulateByGroupByOptions[0].key;
      }
      else if (this.reportRequest.manipulateBy === 'sortBy') {
        this.reportRequest.manipulateDirection = this.manipulateBySortByOptions[0].key;
      }
    },
    onReset() {
      this.reportRequest.template = null;
      this.reportRequest.name = null;
      this.reportRequest.format = null;
      this.reportRequest.manipulateBy = null;
      this.reportRequest.manipulateDirection = null;

      this.$validator.reset();
    },
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      try {
        ProjectService.bulletinReport(this.reportRequest)
          .then(() => Toast.success(this, "Successfully downloaded bulletin report!"))
          .catch(() => Toast.danger(this, "Oops! An error has occured"));

        this.$validator.reset();
      } catch {
        Toast.danger(this, "Oops! An error has occured");
      }
    },
    onCloseModal() {
      this.$bvModal.hide("bv-modal-bulletin-report");
    }
  }
};
</script>
