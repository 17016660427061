<template>
  <fragment>
    <div :class="['sidebar_page', { 'left_side_closed': !showSidebar }]">
      <app-navbar></app-navbar>

      <div class="container-fluid">
        <div class="row">
          <loading :active.sync="isPageLoading" is-full-page></loading>

          <project-advanced-search :showSidebar="showSidebar" :searchCriteria.sync="searchCriteria" ref="projectAdvanceSearch"></project-advanced-search>

          <div class="col-xl-9 px-xl-4 py-4 right_side">
            <div class="page-title d-flex align-items-center">
              <button type="button" class="mr-1 bg-transparent border-0 d-print-none" @click="toggleSidebar()" aria-label="Toggle search">
                <i class="fas fa-lg" v-bind:class="[showSidebar ? 'fa-chevron-left': 'fa-chevron-right' ]"></i>
              </button>
              <h1>Find A Project</h1>
            </div>

            <div class="page-content">
              <component
                v-bind:is="currentTabComponent"
                :isDataLoading.sync="isDataLoading"
                :projects="searchResults.results"
                :searchCriteria="searchCriteria"
                :searchResults="searchResults"
                :documentSearch="searchCriteria.documentSearch"
                @change-tab="changeTab"
                @trigger-search="triggerAdvancedSearchSubmit"
              ></component>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="bv-modal-project-saved-search"
      hide-header
      hide-footer
      :no-close-on-backdrop="true"
    >
      <project-saved-search :searchCriteria.sync="searchCriteria" />
    </b-modal>
  </fragment>
</template>

<script>
import { ProjectService } from "@/services/";
import { mapGetters, mapActions } from "vuex";
import { DocumentSearchService } from "@/services/";
import AppNavBar from "@/components/app-navbar.vue";
import ProjectAdvancedSearch from "@/views/project/components/project-advanced-search.vue";
import ProjectSavedSearch from "@/views/project/components/project-saved-search.vue";
import ProjectSearchResults from "@/views/project/components/project-search-results.vue";
import ProjectSearchMaps from "@/views/project/components/project-search-maps.vue";
import Toast from "@/utils/toast";

const STORAGE_KEY_PROJECT_TABLE_COLUMN_SORTING = 'project-table-column-sorting';
const STORAGE_KEY_PROJECT_TABLE_PAGE_SIZE = 'project-table-page-size';

const defaultSearchCriteria = {
  keyword: '',
  documentSearch: false,
  city: null,
  zoneIds: [],
  provideFollowedOnly: false,
  provideHiddenOnly: false,
  createdStart: null,
  createdEnd: null,
  lastUpdatedStart: null,
  lastUpdatedEnd: null,
  siteMeetingStart: null,
  siteMeetingEnd: null,
  classificationTypeId: null,
  classificationOfWorkIds: [],
  ownerTypeId: null,
  tenderStageIds: ['ba1408eb-83d9-4c94-a2ba-6d9436773b36'], // open
  procurementTypeId: null,
  fundingTypeIds: [],
  page: 1,
  pageSize: 10,
  sortOrder: null,
  sortDirection: null,
  selectedSearchId: "00000000-0000-0000-0000-000000000000"
};

export default {
  components: {
    "app-navbar": AppNavBar,
    ProjectAdvancedSearch,
    ProjectSearchResults,
    ProjectSavedSearch,
    ProjectSearchMaps
  },
  data: function() {
    return {
      isInitialLoad: true,
      isPageLoading: true,
      isDataLoading: false,
      currentTabComponent: ProjectSearchResults,
      showSidebar: true,
      projects: [],
      searchResults: { total: 0, results: [] },
      searchCriteria: null
    };
  },
  watch: {
    "searchCriteria.page": function(newValue, oldValue) {
      if (this.isInitialLoad === false && typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.$router.push({ query: { ...this.$route.query, ...{ page: newValue } } }).catch(() => {}); // catch duplicated navigation
      }
    },
    "searchCriteria.pageSize": function(newValue, oldValue) {
      if (this.isInitialLoad === false && typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.$router.push({ query: { ...this.$route.query, ...{ page: 1, pageSize: newValue } } }).catch(() => {}); // catch duplicated navigation
      }
    },
    "searchCriteria.sortDirection": function(newValue, oldValue) {
      if (this.isInitialLoad === false && typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.getSearchResults();
      }
    },
    "searchCriteria.sortOrder": function(newValue, oldValue) {
      if (this.isInitialLoad === false && typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.getSearchResults();
      }
    }
  },
  computed: {
    ...mapGetters(["getCurrentUser"])
  },
  methods: {
    triggerAdvancedSearchSubmit () {
      this.$refs.projectAdvanceSearch.onSubmit();
    },
    changeTab(tab) {
      switch (tab) {
        case "RESULTS":
          this.currentTabComponent = ProjectSearchResults;
          break;
        case "MAPS":
          this.currentTabComponent = ProjectSearchMaps;
          break;
        default:
      }
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    async getSearchResults() {
      if (!this.isPageLoading) {
        this.isDataLoading = true;
      }

      let params = { ...this.searchCriteria };

      // convert query string flattened values into nested objects
      const qsConverter = function (obj, key) {
        var _items = Object.keys(obj).filter(i => i.indexOf(key) >= 0);

        if (!_items.length) return obj;

        obj[key] = {};

        _items.forEach(i => {
          let v = i.replace(key, '');
          obj[key][v] = obj[i];
          delete obj[i];
        })

        return obj;
      };

      qsConverter(params, 'created');
      qsConverter(params, 'closing');
      qsConverter(params, 'lastUpdated');
      qsConverter(params, 'siteMeeting');
      qsConverter(params, 'awardedDate');


      let originalResult;

      if (params['documentSearch'] === 'true' || params['documentSearch'] === true) {
        params.pageSize = 100;
        this.searchCriteria.pageSize = 100;
        originalResult = await ProjectService.searchWithKeywordDensity(params);
        this.searchResults.results = originalResult.data.results;
      } else {
        originalResult = await ProjectService.search(params);
        this.searchResults.results = originalResult.data.results.map( r => { r.keywordDensity = "---"; return r });
      }

      this.searchResults = originalResult.data;
      this.isDataLoading = false;
    },
    getKeywordDensity(keyword, projectId) {
      return DocumentSearchService.getKeywordDensityByProjectId(keyword, projectId).then(response => {
        return new Promise( resolve => {
          if(response.status === 200){
            resolve(response.data.keywordDensityTotal);
          }else{
            resolve(0);
          }
        });
      })
      .catch( error => {
        throw(error);
      });
    },
    saveSearch() {
      this.$bvModal.show("bv-modal-project-saved-search");
    },
    onSubmit() {
      this.getSearchResults();
    },
    ...mapActions([
      "fetchClientSettings",
      "fetchProcurementTypes",
      "fetchProjects",
      "fetchProjectSearches",
      "fetchFundingTypes",
      "fetchOwnerTypes",
      "fetchClassificationTypes",
      "fetchTenderStages",
      "fetchZones",
      "fetchUser"
    ])
  },
  beforeMount() {
    let savedColumnSort = JSON.parse(window.localStorage.getItem(STORAGE_KEY_PROJECT_TABLE_COLUMN_SORTING)) || {};
    let savedPageSort = JSON.parse(window.localStorage.getItem(STORAGE_KEY_PROJECT_TABLE_PAGE_SIZE)) || {};
    this.searchCriteria = { ...defaultSearchCriteria, ...savedColumnSort, ...savedPageSort, ...this.$route.query };

    // this is a hack to correct when only array option is selected, it will get bound as a string on page reload
    if (this.searchCriteria.zoneIds && !Array.isArray(this.searchCriteria.zoneIds)) {
      this.searchCriteria.zoneIds = [this.searchCriteria.zoneIds];
    }
    if (this.searchCriteria.classificationOfWorkIds && !Array.isArray(this.searchCriteria.classificationOfWorkIds)) {
      this.searchCriteria.classificationOfWorkIds = [this.searchCriteria.classificationOfWorkIds];
    }
    if (this.searchCriteria.tenderStageIds && !Array.isArray(this.searchCriteria.tenderStageIds)) {
      this.searchCriteria.tenderStageIds = [this.searchCriteria.tenderStageIds];
    }

    // this is a hack to correct above code from setting stage to 'open' when user select 'all', which is a null value
    if (Object.keys(this.$route.query).length > 1 && !this.$route.query['tenderStageId']) {
      this.searchCriteria.tenderStageId = null
    }

    // if tenderStageId (singular) is passed, then we'll assign it to the collection and set to null
    if (Object.keys(this.$route.query).length > 1 && this.$route.query['tenderStageId']) {
      this.searchCriteria.tenderStageIds = [...this.searchCriteria.tenderStageIds, ...[this.$route.query['tenderStageId']]];
      this.searchCriteria.tenderStageId = null;
    }

    if ((!Object.keys(this.$route.query).length) || (Object.keys(this.$route.query).length == 1 && this.$route.query['page'])) {
      let _user = this.getCurrentUser;

      this.fetchUser(_user.id).then(user => {
        if (user.isClientAdmin || user.isSystemAdmin) {
          let _zoneIds = user.zones.map(({ zoneId }) => zoneId);
          this.searchCriteria.zoneIds = [...new Set(_zoneIds)];
        } else {
          let _targetedZoneIds = user.companies.map(function(e) { return e.targetedZoneIds; }).flat();
          this.searchCriteria.zoneIds = [...new Set(_targetedZoneIds)];
        }
      });
    }
  },
  mounted: function() {
    this.isInitialLoad = Object.keys(this.$route.query).length === 0 && this.$route.query.constructor === Object;

    Promise.all([
      this.fetchClientSettings(),
      this.fetchProcurementTypes(),
      this.fetchProjectSearches(),
      this.fetchFundingTypes(),
      this.fetchTenderStages(),
      this.fetchOwnerTypes(),
      this.fetchClassificationTypes(),
      this.fetchZones(),
      this.isInitialLoad || this.getSearchResults()
    ])
    .then(() => {
      this.isPageLoading = false;
    })
    .catch(() => {
      this.isPageLoading = false;
      let message = this.$createElement('span', {}, ['Oops! An error has occured and some data could not be loaded', this.$createElement('br'), 'Please contact an administrator']);
      Toast.danger(this, [message], { autoHideDelay: 7500 });
    });
  }
};
</script>
