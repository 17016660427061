<template>
  <div id="sidebar" class="col col-xl-3 px-xl-4 py-4 border-right bg-light left_side d-print-none" :hidden="!showSidebar">
    <app-adspace code="PROJECT_SEARCH" size="4x3" :count="3" :adImages="this.adOneImages" v-if="this.hasData"></app-adspace>
    <form v-on:submit.prevent="onSubmit">
      <fieldset>
        <legend>Narrow Your Results</legend>

        <div class="form-group">
          <label class="sr-only" for="mySearchesDropdown">Saved searches</label>
          <div class="input-group">
            <select id="mySearchesDropdown" name="mySearchesDropdown" class="custom-select" ref="savedSearchDropdown"
                v-model="searchCriteria.selectedSearchId" @change="selectSavedSearch">
              <option value="00000000-0000-0000-0000-000000000000" disabled>My Searches</option>
              <option v-for="search in getProjectSavedSearches" :key="search.id" :value="search.id">{{ search.name }}</option>
            </select>
            <div class="input-group-append">
              <button class="btn btn-sm btn-outline-danger" type="button" aria-label="Delete saved search" @click="deleteSavedSearch">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="keywords">Keyword</label>
          <input id="keywords" name="keywords" aria-describedby="searchTip" class="form-control w-100 rounded p-1 px-2" :class="{ 'is-invalid': (errors.has('keywords') || (searchCriteria.documentSearch && searchCriteria.keyword.length < 3)) }" placeholder="Search by Keyword" autocomplete="off" type="text" v-model="searchCriteria.keyword" ref="keyword" v-validate="{ required: searchCriteria.documentSearch}" />          
          <small id="searchTip">Tip: Use quotes to search for exact phrases</small>
          <div class="invalid-feedback">At least 3 characters in the keyword box is required to use the document search feature</div>
        </div>

        <div class="form-group" v-if="showDocumentSearch">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="documentSearch" name="documentSearch" v-model="searchCriteria.documentSearch" :value="false" />
            <label class="form-check-label" for="documentSearch">Document Search</label>
          </div>
        </div>

        <div class="form-group">
          <label for="City">City</label>
          <input class="form-control w-100 rounded p-1 px-2" id="City" name="City" placeholder="Search by City" autocomplete="off" type="text" v-model="searchCriteria.city" />
        </div>

        <div class="form-group">
          <label for="zone">Zones</label>
          <v-select id="zone" name="zone" class="v-select-multi" style="width: 100%" multiple label="name"
            :reduce="zone => zone.id" :options="getZones" v-model="searchCriteria.zoneIds" :closeOnSelect="false">
          </v-select>
        </div>

        <div class="form-group">
          <label class="control2 control-radio">
            My Projects
            <input type="radio" name="provideFollowedOnly" v-model="searchCriteria.provideFollowedOnly" :value="true" />
            <span class="control_indicator2"></span>
          </label>

          <label class="control2 control-radio">
            All Projects
            <input type="radio" name="provideFollowedOnly" v-model="searchCriteria.provideFollowedOnly" :value="false" />
            <div class="control_indicator2"></div>
          </label>
        </div>

        <div class="form-group" v-if="$permissions().isClientAdmin || $permissions().isSysAdmin || $permissions().hasAddPrivateProjectPermission">
          <div class="form-check" v-if="$permissions().isClientAdmin || $permissions().isSysAdmin">
            <input id="hidden-only" class="form-check-input" type="checkbox" name="provideHiddenOnly" v-model="searchCriteria.provideHiddenOnly" :value="true" />
            <label class="form-check-label" for="hidden-only">Show Only Hidden Projects</label>
          </div>

          <div class="form-check" v-if="$permissions().hasAddPrivateProjectPermission">
            <input id="private-projects" class="form-check-input" type="checkbox" name="provideHiddenOnly" v-model="searchCriteria.providePrivateProjects" :value="true" />
            <label class="form-check-label" for="private-projects">Show My Private Projects</label>
          </div>
        </div>
      </fieldset>

      <fieldset class="mt-2 advanced-search" v-if="$permissions().hasProjectAdvancedSearch">
        <legend>Advanced Search</legend>

        <div class="card" id="dates-card">
          <div class="card-header row align-items-center" data-toggle="collapse" data-target="#dates-collapse">
            <div class="card-text col">
              <i class="far fa-calendar fa-lg mr-2" aria-hidden="true"></i>
              <span>Dates</span>
            </div>
            <i class="fas fa-chevron-down mx-2" aria-hidden="true"></i>
          </div>
          <div class="collapse" id="dates-collapse">
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label for="DateCreatedFrom">Date Created From</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent">
                        <img src="@/assets/cal.svg" alt />
                      </span>
                    </div>
                    <date-picker
                      id="DateCreatedFrom"
                      class="datepickerfrom border-left-0"
                      :config="datepickerOptions"
                      v-model="searchCriteria.createdStart"
                    />
                  </div>
                </div>

                <div class="form-group col-sm-6">
                  <label for="DateCreatedTo">Date Created To</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent">
                        <img src="@/assets/cal.svg" alt />
                      </span>
                    </div>
                    <date-picker
                      id="DateCreatedTo"
                      class="datepickerfrom border-left-0"
                      :config="datepickerOptions"
                      v-model="searchCriteria.createdEnd"
                    />
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label for="ClosingDateFrom">Closing Date From</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent pr-1">
                        <img src="@/assets/cal.svg" alt />
                      </span>
                    </div>
                    <date-picker
                      id="ClosingDateFrom"
                      class="datepickerfrom form-control border-left-0"
                      :config="datepickerOptions"
                      v-model="searchCriteria.closingStart"
                    />
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <label for="ClosingDateTo">Closing Date To</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent pr-1">
                        <img src="@/assets/cal.svg" alt />
                      </span>
                    </div>
                    <date-picker
                      id="ClosingDateTo"
                      class="datepickerfrom form-control border-left-0"
                      :config="datepickerOptions"
                      v-model="searchCriteria.closingEnd"
                    />
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label for="SiteMeetingFrom">Site Meeting From</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent pr-1">
                        <img src="@/assets/cal.svg" alt />
                      </span>
                    </div>
                    <date-picker
                      id="SiteMeetingFrom"
                      class="datepickerfrom form-control border-left-0"
                      :config="datepickerOptions"
                      v-model="searchCriteria.siteMeetingStart"
                    />
                  </div>
                </div>

                <div class="form-group col-sm-6">
                  <label for="SiteMeetingTo">Site Meeting To</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent pr-1">
                        <img src="@/assets/cal.svg" alt />
                      </span>
                    </div>
                    <date-picker
                      id="SiteMeetingTo"
                      class="datepickerfrom form-control border-left-0"
                      :config="datepickerOptions"
                      v-model="searchCriteria.siteMeetingEnd"
                    />
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label for="LastUpdatedFrom">Last Updated From</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent pr-1">
                        <img src="@/assets/cal.svg" alt />
                      </span>
                    </div>
                    <date-picker
                      id="LastUpdatedFrom"
                      class="datepickerfrom form-control border-left-0"
                      :config="datepickerOptions"
                      v-model="searchCriteria.lastUpdatedStart"
                    />
                  </div>
                </div>

                <div class="form-group col-sm-6">
                  <label for="LastUpdatedTo">Last Updated To</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-transparent pr-1">
                        <img src="@/assets/cal.svg" alt />
                      </span>
                    </div>
                    <date-picker
                      id="LastUpdatedTo"
                      class="datepickerfrom form-control border-left-0"
                      :config="datepickerOptions"
                      v-model="searchCriteria.lastUpdatedEnd"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" id="classes-card">
          <div class="card-header row align-items-center" data-toggle="collapse" data-target="#classes-collapse">
            <div class="card-text col">
              <i class="fas fa-border-all fa-lg mr-2" aria-hidden="true"></i>
              <span>Classification of Work</span>
            </div>
            <i class="fas fa-chevron-down mx-2" aria-hidden="true"></i>
          </div>
          <div class="collapse" id="classes-collapse">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <label class="control2 control-radio">
                    All
                    <input id="ClassificationTypeId" name="ClassificationTypeId" type="radio" :value="null"
                      v-model="searchCriteria.classificationTypeId" checked="checked" />
                    <span class="control_indicator2"></span>
                  </label>
                </div>

                <div class="col" v-for="classification in getClassificationTypes" :key="classification.id">
                  <label class="control2 control-radio">
                    {{ classification.name }}
                    <input type="radio" name="ClassificationTypeId" :value="classification.id" v-model="searchCriteria.classificationTypeId" />
                    <div class="control_indicator2"></div>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div id="project-classification-of-works-container" v-if="searchCriteria.classificationTypeId && getClassificationsOfWork.length > 0">
                    <label class="control control-checkbox" v-for="work in getClassificationsOfWork" :key="work.id">
                      {{ work.name }}
                      <input type="checkbox" name="ClassificationOfWorks" :value="work.id" v-model="searchCriteria.classificationOfWorkIds" />
                      <div class="control_indicator"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" id="owner-type-card">
          <div class="card-header row align-items-center" data-toggle="collapse" data-target="#owner-type-collapse">
            <div class="card-text col">
              <i class="fas fa-university fa-lg mr-2" aria-hidden="true"></i>
              <span>Owner Type</span>
            </div>
            <i class="fas fa-chevron-down mx-2" aria-hidden="true"></i>
          </div>
          <div class="collapse" id="owner-type-collapse">
            <div class="card-body">
              <div class="form-group">
                <label class="sr-only" for="OwnerTypeId">Owner Type</label>
                <select id="OwnerTypeId" name="OwnerTypeId" class="custom-select w-100 rounded p-1 px-2" v-model="searchCriteria.ownerTypeId">
                  <option :value="null">All</option>
                  <option v-for="type in getOwnerTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="card" id="stage-card">
          <div class="card-header row align-items-center" data-toggle="collapse" data-target="#stage-collapse">
            <div class="card-text col">
              <i class="far fa-chart-bar fa-lg mr-2" aria-hidden="true"></i>
              <span>Stage</span>
            </div>
            <i class="fas fa-chevron-down mx-2" aria-hidden="true"></i>
          </div>
          <div class="collapse" id="stage-collapse">
            <div class="card-body">
              <div class="form-group">
                <label class="sr-only" for="tenderStageIds">Stage</label>
                <v-select id="tenderStageIds" name="tenderStageIds" class="v-select-multi" style="width: 100%" multiple label="name"
                  :reduce="stage => stage.id" :options="tenderStagesWithoutPermits" v-model="searchCriteria.tenderStageIds" :closeOnSelect="false">
                </v-select>
                <small class="form-text text-muted mt-0 mb-1">
                  Keep this blank to select all stages
                </small>
              </div>
            </div>
          </div>
        </div>

        <div class="card" id="procurement-type-card">
          <div class="card-header row align-items-center" data-toggle="collapse" data-target="#procurement-type-collapse">
            <div class="card-text col">
              <i class="fas fa-book fa-lg mr-2" aria-hidden="true"></i>
              <span>Procurement Type</span>
            </div>
            <i class="fas fa-chevron-down mx-2" aria-hidden="true"></i>
          </div>
          <div class="collapse" id="procurement-type-collapse">
            <div class="card-body">
              <div class="form-group">
                <label class="sr-only" for="ProcurementTypeId">Procurement Type</label>
                <select id="ProcurementTypeId" name="ProcurementTypeId" class="custom-select w-100 rounded p-1 px-2 select2-control"
                    v-model="searchCriteria.procurementTypeId">
                  <option :value="null">All</option>
                  <option v-for="procurementType in getProcurementTypes" :key="procurementType.id" :value="procurementType.id">{{ procurementType.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="card" id="funding-type-card">
          <div class="card-header row align-items-center" data-toggle="collapse" data-target="#funding-type-collapse">
            <div class="card-text col">
              <i class="fas fa-coins fa-lg mr-2" aria-hidden="true"></i>
              <span>Funding Type</span>
            </div>
            <i class="fas fa-chevron-down mx-2" aria-hidden="true"></i>
          </div>
          <div class="collapse" id="funding-type-collapse">
            <div class="card-body">
              <div class="form-group">
                <label class="sr-only" for="fundingTypeId">Funding Type</label>
                <v-select id="fundingTypeIds" name="fundingTypeIds" class="v-select-multi" style="width: 100%" multiple label="name"
                  :reduce="stage => stage.id" :options="getFundingTypes" v-model="searchCriteria.fundingTypeIds" :closeOnSelect="false">
                </v-select>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <button type="submit" class="btn-primary btn btn-block">Search</button>

      <div class="col mt-2">
        <div class="row">
          <button type="button" class="col btn-secondary btn" title="Save Search" @click="saveSearch"><i class="far fa-save"></i> Save Search</button>
          <button type="button" class="col btn-secondary btn" title="Clear All" @click="clearSearch">Clear All</button>
        </div>
      </div>
    </form>
    <app-adspace code="PROJECT_SEARCH" size="4x3" :count="3" :adImages="this.adTwoImages" v-if="this.hasData"></app-adspace>
  </div>
</template>

<style scoped>
.card {
  margin-bottom: 8px;
  cursor: pointer;
}
</style>

<script>
import cloneDeep from "lodash/cloneDeep";
import { mapGetters, mapActions } from "vuex";
import DatePicker from "vue-bootstrap-datetimepicker";
import { clean as CleanObject } from "@/utils/object";
import Toast from "@/utils/toast";
import MessageBox from "@/utils/messagebox";
import { AdvertisementGroupService } from "@/services/";
import "@/styles/search-sidebar.css";

export default {
  name: "project-advanced-search",
  components: {
    'app-adspace': () => import("@/components/app-adspace.vue"),
    DatePicker
  },
  props: {
    showSidebar: { type: Boolean, default: () => true },
    searchCriteria: { type: Object, required: true }
  },
  data: function() {
    return {
      adOneImages:[],
      adTwoImages:[],
      datepickerOptions: {
        useCurrent: false,
        format: "MM/DD/YYYY"
      },
      hasData: false
    };
  },
  computed: {
    getClassificationsOfWork: function() {
      if (this.searchCriteria.classificationTypeId) {
        let selectedClassificationType = this.getClassificationTypes.find(
          type => type.id === this.searchCriteria.classificationTypeId
        );
        if (selectedClassificationType) {          
          return selectedClassificationType.classificationOfWorks.sort((a,b) => a.name.localeCompare(b.name));
        }
      }
      return [];
    },
    showDocumentSearch() {
        return (this.getUseDocumentSearch || '').toUpperCase() === 'TRUE'
    },
    tenderStagesWithoutPermits() {
      return this.getTenderStages.filter(stage => !stage.name.toLowerCase().includes('permit'));
    },
    ...mapGetters([
      "getClassificationTypes",
      "getFundingTypes",
      "getOwnerTypes",
      "getProcurementTypes",
      "getProjectSavedSearches",
      "getTenderStages",
      "getZones",
      "getUseDocumentSearch"
    ])
  },
  methods: {
    ...mapActions([
      "removeProjectSearch",
      "fetchClientSettings"
    ]),
    clearSearch() {
      this.$nextTick(() => {
        // ideally this all gets moved into vuex to handle better across components
        this.searchCriteria.keyword = '';
        this.searchCriteria.documentSearch = false,
        this.searchCriteria.city = null;
        this.searchCriteria.zoneIds = [];
        this.searchCriteria.provideFollowedOnly = false;
        this.searchCriteria.provideHiddenOnly = false;
        this.searchCriteria.createdStart = null;
        this.searchCriteria.createdEnd = null;
        this.searchCriteria.closingStart = null;
        this.searchCriteria.closingEnd = null;
        this.searchCriteria.siteMeetingStart = null;
        this.searchCriteria.siteMeetingEnd = null;
        this.searchCriteria.lastUpdatedStart = null;
        this.searchCriteria.lastUpdatedEnd = null;
        this.searchCriteria.classificationTypeId = null;
        this.searchCriteria.classificationOfWorkIds = [];
        this.searchCriteria.ownerTypeId = null;
        this.searchCriteria.tenderStageIds = ['ba1408eb-83d9-4c94-a2ba-6d9436773b36']; // open
        this.searchCriteria.procurementTypeId = null;
        this.searchCriteria.fundingTypeIds = [];
        this.searchCriteria.selectedSearchId = "00000000-0000-0000-0000-000000000000";
        this.searchCriteria.awardedDate = null
        this.searchCriteria.lastUpdated = null
        this.searchCriteria.created = null
        this.searchCriteria.closing = null
        this.searchCriteria.siteMeetingPriorities = null;
      })
    },
    async deleteSavedSearch () {
      let searchId = this.$refs.savedSearchDropdown.value;

      if (searchId) {
        let confirm = await MessageBox.confirm(this, "Remove Saved Search", "Are you sure you want to remove the selected saved search?");

        if (confirm) {
          this.removeProjectSearch(searchId)
            .then(() => Toast.success(this, "Successfully removed saved search!"))
            .catch(() => Toast.danger(this, "Oops! An error has occured"));
        }
      }
    },
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid || (this.searchCriteria.documentSearch && this.searchCriteria.keyword.length < 3)) {
        window.scrollTo(0, 0);
        return;
      }

      this.searchCriteria.page = 1;
      let params = cloneDeep(this.searchCriteria);

      CleanObject(params);

      if (params.lastUpdated) {
        params.lastUpdated.start && (params.lastUpdatedStart = params.lastUpdated.start);
        params.lastUpdated.end && (params.lastUpdatedEnd = params.lastUpdated.end);
        params.lastUpdated.lastNthHours && (params.lastUpdatedLastNthHours = params.lastUpdated.lastNthHours);
        params.lastUpdated.lastNthDays && (params.lastUpdatedLastNthDays = params.lastUpdated.lastNthDays);
        delete params['lastUpdated'];
      }
      if (params.siteMeeting) {
        params.siteMeeting.start && (params.siteMeetingStart = params.siteMeeting.start);
        params.siteMeeting.end && (params.siteMeetingEnd = params.siteMeeting.end);
        params.siteMeeting.lastNthHours && (params.siteMeetingLastNthHours = params.siteMeeting.lastNthHours);
        params.siteMeeting.lastNthDays && (params.siteMeetingLastNthDays = params.siteMeeting.lastNthDays);
        delete params['siteMeeting'];
      }
      if (params.awardedDate) {
        params.awardedDate.start && (params.awardedDateStart = params.awardedDate.start);
        params.awardedDate.end && (params.awardedDateEnd = params.awardedDate.end);
        params.awardedDate.lastNthHours && (params.awardedDateLastNthHours = params.awardedDate.lastNthHours);
        params.awardedDate.lastNthDays && (params.awardedDateLastNthDays = params.awardedDate.lastNthDays);
        delete params['awardedDate'];
      }
      if (params.closing) {
        params.closing.start && (params.closingStart = params.closing.start);
        params.closing.end && (params.closingEnd = params.closing.end);
        params.closing.lastNthHours && (params.closingLastNthHours = params.closing.lastNthHours);
        params.closing.lastNthDays && (params.closingLastNthDays = params.closing.lastNthDays);
        delete params['closing'];
      }
      if (params.created) {
        params.created.start && (params.createdStart = params.created.start);
        params.created.end && (params.createdEnd = params.created.end);
        params.created.lastNthHours && (params.createdLastNthHours = params.created.lastNthHours);
        params.created.lastNthDays && (params.createdLastNthDays = params.created.lastNthDays);
        delete params['created'];
      }

      if (!params.keyword) delete params['keyword'];
      if (!params.documentSearch) delete params['documentSearch'];
      if (!params.provideFollowedOnly) delete params['provideFollowedOnly'];
      if (!params.provideHiddenOnly) delete params['provideHiddenOnly'];
      if (!params.tenderStageIds) params.tenderStageIds = null;
      if (!params.selectedSearchId || params.selectedSearchId === '00000000-0000-0000-0000-000000000000') delete params['selectedSearchId'];

      this.$router.push({ query: params }).catch(() => {}); // catch duplicated navigation
    },
    saveSearch() {
      this.$bvModal.show("bv-modal-project-saved-search");
    },
    selectSavedSearch() {
      let searchId = this.$refs.savedSearchDropdown.value;
      let search = this.getProjectSavedSearches.find(i => i.id === searchId);

      if (search) {
        let parsed = JSON.parse(JSON.stringify(search));

        // remove unnecessary items
        delete parsed['id'];
        delete parsed['name'];
        delete parsed['userId'];
        delete parsed['isPublic'];
        delete parsed['isLock'];
        delete parsed['clientId'];
        delete parsed['selectedSearchId'];

        this.searchCriteria = parsed;
        this.searchCriteria.selectedSearchId = searchId;
        this.onSubmit();
      }
    }
  },
  mounted() {
    Promise.all([
      this.fetchClientSettings()
    ]);
    AdvertisementGroupService.getAllAds("PROJECT_SEARCH").then(({ data }) => {
        var results = data.map(({ id, imgAltText, imgSrc, linkTitleText, linkToUrl }) => ({ id, imgAltText, imgSrc, linkTitleText, linkToUrl }));
        if(results.length >1){
          var mid = Math.round(results.length/2);
          this.adOneImages = results.slice(0,mid);
          this.adTwoImages = results.slice(mid);
        }else{
          this.adOneImages = results;
        }
        this.hasData = true;
    });
  }
};
</script>
