<template>
  <div>
    <div id="map" class="google-map" ref="googleMap"></div>
    <template v-if="this.google && this.map && this.info">
      <slot :google="google" :map="map" :info="info" />
    </template>
  </div>
</template>

<style scoped>
#map {
  width: 100%;
  height: 600px;
  background-color: grey;
}
</style>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";

export default {
  props: { mapConfig: Object, apiKey: String, markers: { type: Array } },
  data() {
    return {
      google: null,
      map: null,
      info: null
    };
  },
  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
      this.info = new this.google.maps.InfoWindow();

      let bounds = new this.google.maps.LatLngBounds();
      this.markers.forEach(marker => bounds.extend(marker.position));
      this.map.fitBounds(bounds);
      this.map.setZoom(this.mapConfig.zoom || 10);
    }
  },
  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({ apiKey: this.apiKey });
    this.google = googleMapApi;
    this.initializeMap();
  }
};
</script>
