<template>
  <fragment>
    <div class="text-right">
      <button class="btn btn-link" @click="goToResultsView">
        <i class="fas fa-arrow-left" aria-hidden="true"></i>
        <strong>Go Back to Results</strong>
      </button>
    </div>

    <div class="map-container">
      <google-map-loader :mapConfig="mapConfig" :markers="markers" :apiKey="getGoogleMapKey" :key="reloadKey">
        <template slot-scope="{ google, map, info }">
          <google-map-marker
            v-for="marker in markers"
            :key="marker.id"
            :marker="marker"
            :google="google"
            :map="map"
            :info="info"
          />
        </template>
      </google-map-loader>
    </div>
  </fragment>
</template>

<script>
import { mapGetters } from "vuex";
import { GoogleMapLoader, GoogleMapMarker } from "@/components/google-maps";

export default {
  name: "project-search-maps",
  components: { GoogleMapLoader, GoogleMapMarker },
  props: ["apiKey", "projects"],
  data() {
    return {
      reloadKey: 'google-map-loader-key-' + Date.now(),
      markers: [],
      mapConfig: {
        zoom: 13
      }
    };
  },
  watch: {
    projects: function() {
      this.markers = this.generateMarkers();
      this.reloadKey = 'google-map-loader-key-' + Date.now();
    }
  },
  computed: {
    ...mapGetters(["getGoogleMapKey"])
  },
  methods: {
    generateMarkers() {
      let constructMarkers = project => project.addresses.filter(address => filterMarkers(address)).map(address => constructMarker(project,address));
      let filterMarkers = (address) => {
        return !(address==undefined || address.latitude == undefined || address.latitude == null || address.latitude == ''|| address.longitude == undefined || address.longitude == null || address.longitude == '');
      }
      let constructMarker = (project, address) => {
        let closingDate = this.$options.filters.datetime(project.closingDate);
        let url = `<a href="/project/info/${project.id}">View Project Details</a>`;

        let content = `
          <div>${project.title}</div>
          <div>${project.owner?.name || 'N/A' }</div>
          <div>${closingDate}</div>
          <div>${url}</div>
        `;

        return {
          id: address.id,
          title: project.title,
          content: content,
          position: { lat: address.latitude, lng: address.longitude }
        };
      };

      return this.projects.flatMap(constructMarkers);
    },
    goToResultsView() {
      this.$emit("change-tab", "RESULTS");
    }
  },
  mounted() {
    this.markers = this.generateMarkers();
  }
};
</script>
