var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('data-table',{attrs:{"data":_vm.searchResults.results,"columns":_vm.columns,"total":_vm.searchResults.total,"query":_vm.searchCriteria,"selectedRows":_vm.selectedRecords,"isLoading":_vm.isDataLoading,"column-filter":_vm.$permissions().hasProjectSearchColumns,"onRowDblClick":_vm.goToProject,"rowClass":_vm.setContextualClass},on:{"selected-columns-changed":_vm.onSelectedColumnsChanged,"column-sort-changed":_vm.onColumnSortChanged,"page-size-changed":_vm.onPageSizeChanged},scopedSlots:_vm._u([{key:"header-custom-btns",fn:function(){return [_c('button',{staticClass:"btn btn-outline-dark",attrs:{"type":"button","disabled":_vm.searchResults.total === 0},on:{"click":_vm.goToMapView}},[_vm._v("View Map")]),(_vm.$permissions().hasProjectExcelExport)?_c('button',{staticClass:"btn btn-outline-dark ld-ext-right",class:{ 'running' : _vm.isExporting },attrs:{"type":"button","disabled":_vm.isExporting || _vm.searchResults.results.length === 0},on:{"click":function($event){return _vm.exportDataset(false)}}},[_vm._v(" Export to Excel "),_c('div',{staticClass:"ld ld-ring ld-spin"})]):_vm._e(),(_vm.$permissions().hasProjectExcelExport)?_c('button',{staticClass:"btn btn-outline-dark ld-ext-right",class:{ 'running' : _vm.isExporting },attrs:{"type":"button","disabled":_vm.isExporting || _vm.searchResults.results.length === 0},on:{"click":function($event){return _vm.exportDataset(true)}}},[_vm._v(" Export All to Excel "),_c('div',{staticClass:"ld ld-ring ld-spin"})]):_vm._e(),_c('button',{staticClass:"btn btn-outline-dark",attrs:{"type":"button"},on:{"click":_vm.print}},[_vm._v("Print")])]},proxy:true},{key:"ocaaNumber",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.ocaaNumber))]}},{key:"lcaNumber",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.lcaNumber))]}},{key:"title",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.title))]}},{key:"classificationOfWork",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.classificationOfWork))]}},(_vm.showDocumentSearch)?{key:"keywordDensity",fn:function(ref){
var row = ref.row;
return [_c('span',{domProps:{"innerHTML":_vm._s(row.keywordDensity)}},[_vm._v(_vm._s(row.keywordDensity))])]}}:null,{key:"closingDate",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("datetime")(row.closingDate,'MMM dd, yyyy h:mm a'))+" "+_vm._s(row.timeZone.code))]}},{key:"owner.owner.name",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.owner || '---'))]}},{key:"ownerType.name",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.ownerType || '---'))]}},{key:"addenda",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.addenda))]}},{key:"siteMeeting",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.siteMeetings.length ? row.siteMeetings[0].text : '---'))]}},{key:"belongZone.name",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.belongZone))]}},{key:"city",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.getCitiesDisplay(row.addresses)))]}},{key:"tenderStage.name",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.tenderStage))]}},{key:"procurementType",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.procurementType))]}},{key:"funding.name",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.funding))]}},{key:"budget",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.budget))]}},{key:"ownerSolicitationNumber",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ownerSolicitationNumber)+" ")]}},{key:"bids.company.name",fn:function(ref){
var row = ref.row;
return [_vm._l((_vm.filteredRowBids(row.bids)),function(bidder,index){return _c('span',{key:'search-result-bidder-' + index},[_vm._v(" "+_vm._s(bidder.companyName)+" "),_c('br')])}),(row.bids.length === 0)?_c('span',[_vm._v("---")]):_vm._e()]}},{key:"contact.companyName",fn:function(ref){
var row = ref.row;
return [_vm._l((row.contacts),function(contact,index){return _c('span',{key:'search-result-contact-' + index},[_vm._v(" "+_vm._s(contact.companyName)+" "),_c('br')])}),(row.contacts.length === 0)?_c('span',[_vm._v("---")]):_vm._e()]}},{key:"addendaInfo",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(row.addendaInfo || '---'))])]}},{key:"createdOn",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("datetime")(row.createdOn,'MMM dd, yyyy h:mm a'))+" "+_vm._s(row.timeZone.code))]}},{key:"updatedOn",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("datetime")(row.updatedOn,'MMM dd, yyyy h:mm a'))+" "+_vm._s(row.updatedOn != null && row.updatedOn != undefined ? row.timeZone.code:""))]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('td',{staticStyle:{"width":"125px"}},[(row.allowView)?_c('follow-button',{staticClass:"btn btn-link btn-sm",attrs:{"projectId":row.id,"projectTitle":row.title,"value":row.isUserFollowing},scopedSlots:_vm._u([{key:"is-following",fn:function(){return [_c('i',{staticClass:"far fa-check-circle"}),_c('span',{staticClass:"sr-only"},[_vm._v("Unfollow")])]},proxy:true},{key:"is-not-following",fn:function(){return [_c('i',{staticClass:"fas fa-rss"}),_c('span',{staticClass:"sr-only"},[_vm._v("Follow")])]},proxy:true}],null,true)}):_vm._e(),_c('router-link',{staticClass:"btn btn-link btn-sm",attrs:{"to":{ name: 'project-info', params: { id: row.id, qs: _vm.$route.query } },"role":"button","aria-label":'View ' + row.title}},[_c('i',{staticClass:"far fa-eye"})]),(row.allowEdit)?_c('router-link',{staticClass:"btn btn-link btn-sm",attrs:{"to":'/project/edit/' + row.id,"role":"button","aria-label":'Edit ' + row.title}},[_c('i',{staticClass:"far fa-edit"})]):_vm._e()],1)]}},{key:"loading",fn:function(){return [_c('loading',{attrs:{"active":_vm.isDataLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isDataLoading=$event}}})]},proxy:true},{key:"no-records",fn:function(){return [(_vm.isInitialLoad === true)?_c('div',{staticClass:"alert alert-info",attrs:{"role":"alert"}},[_vm._v(" Please use the filters on the left and then hit 'Search' or select a saved search from 'My Searches'. "),_c('hr'),_c('button',{staticClass:"btn-primary btn btn-block",attrs:{"type":"submit"},on:{"click":_vm.triggerSearch}},[_vm._v("Search")])]):_vm._e(),(_vm.isInitialLoad === false)?_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_vm._v(" No records found. ")]):_vm._e()]},proxy:true}],null,true)}),_c('b-modal',{attrs:{"id":"bv-modal-bulletin-report","size":"lg","hide-header":"","hide-footer":"","no-close-on-backdrop":true}},[_c('project-bullet-report')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }