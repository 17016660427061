<template>
  <button v-if="isAvailable" type="button" @click="setSubscriptionToProject(projectId)" :title="buttonTitleAttr">
    <slot name="is-following" v-if="value"></slot>
    <slot name="is-not-following" v-if="!value"></slot>
  </button>
</template>

<script>
import { ProjectService } from "@/services/";
import Toast from "@/utils/toast";
import MessageBox from "@/utils/messagebox";

export default {
  name: "project-search-results-follow-button",
  props: {
    value: { type: Boolean, default: () => false },
    projectId: { type: String, required: true },
    projectTitle: { type: String },
  },
  computed: {
    buttonTitleAttr() {
      if (!this.projectTitle) return null;
      return (this.value ? "Following " : "Follow ") + this.projectTitle;
    },
    isAvailable () {
      return this.$permissions().isClientAdmin === false && this.$permissions().isSysAdmin === false;
    }
  },
  methods: {
    async setSubscriptionToProject(projectId) {
      try {
        if (this.value === false) {
          await ProjectService.subscribe(projectId);
          this.$emit('input', true);
          Toast.success(this, `You are now following ${ this.projectTitle }`);
        }
        else if (this.value === true) {
          let confirm = await MessageBox.confirm(this, "Unsubscribe from project", `Are you sure you want to unsubscribe from ${ this.projectTitle }?`);

          if (confirm) {
            await ProjectService.unsubscribe(projectId);
            this.$emit('input', false);
            Toast.success(this, "Successfully unsubscribed from project!");
          }
        }
      }
      catch (ex) {
        Toast.danger(this, "Oops! An error has occured");
      }
    }
  },
  mounted() {
    this.isFollowing = this.value;
  }
};
</script>
